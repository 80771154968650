

































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import CreateBiAcces from "./CreateBiAcces.vue";
import EditBiAcces from "./EditBIAcces.vue";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { BIAcces } from "@/api/models/biAcces/biAcces";

@Component({
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateBiAcces,
    EditBiAcces,
  },
})
export default class ListBIAcces extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewBiAccesSidebarActive = false;
  isUpdateBiAccesSidebarActive = false;
  selectedBIAccesId = "";

  tableColumns = [{ key: "email", sortable: true },{key: "expiration", label: "Date d'expiration", sortable: true}, { key: "actions" }];
  paginatedListBIAcces = new PaginatedList<BIAcces>();

  async created() {
    await this.loadListBIAcces({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListBIAcces.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListBIAcces.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListBIAcces({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  editBIAcces(biAccesId: string) {
    this.selectedBIAccesId = biAccesId;
    this.isUpdateBiAccesSidebarActive = true;
  }

  async loadListBIAcces(params: any) {
    if (!params || params?.reset) {
      this.paginatedListBIAcces = new PaginatedList<BIAcces>();
    }
    await this.$http.myressif.biAcces
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: PaginatedList<BIAcces>) => {
        this.paginatedListBIAcces = response;
      });
  }



  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cet accès ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.biAcces
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un accès",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListBIAcces({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
