





























































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BFormDatepicker,
  BFormCheckboxGroup,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { BIAcces, UpdateBIAccesModel } from "@/api/models/biAcces/biAcces";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { BIRapport } from "@/api/models/biRapport/biRapport";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormCheckboxGroup
  },
  name: "edit-bi-acces",
})
export default class EditBiAcces extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateBiAccesSidebarActive!: boolean;
  @Prop({ required: true }) biAccesId!: string;

  loading = false;
  required = required;
  loadingBIAcces = false;

  biAcces: UpdateBIAccesModel = {};
  powerBiRapports: BIRapport[] = [];
  test: [] = [];

  async created() {
    await this.$http.myressif.biAcces
      .getById(this.biAccesId)
      .then((res: BIAcces) => {
        this.biAcces = res;
        console.log(this.biAcces);
        this.loadingBIAcces = true;
      });
      
    await this.$http.myressif.biRapports
      .paginatedList()
      .then(
        (response: PaginatedList<BIRapport>) => (
          this.powerBiRapports = response.items
      ), 
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      })
      
  }

  resetForm() {
    this.biAcces = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.biAcces
      .update(
        this.biAccesId,
        this.biAcces
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du accès",
            text: "Accès modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-biAcces");
        this.$emit("update:is-update-biAcces-sidebar-active", false);
        this.loading = false;
      });
  }
}
